<template> 
   <FeaturesView :data="AllData.Features" />
   <ButtonView/>
  </template>
  <script >
    import ButtonView from "@/components/Button.vue";
    import FeaturesView  from '@/components/FeaturesView.vue'
    import AllData from "@/services/drummydata.json";
    export default {
      name: ' FeaturesPage',
      components: {
    FeaturesView,
    ButtonView
 
  },
      data() {
    return {
      AllData: AllData,
    };
  },
    }
  </script>
  <style>
  </style>