<template> 
   <section>
    <AboutUS :data="AllData.about_us"/>
    <ButtonView/>
   </section>
</template>
<script >
  import ButtonView from "@/components/Button.vue";
import AboutUS from "@/components/AboutUs.vue";
import AllData from "@/services/drummydata.json";
  export default {
    name: 'AboutUSPage',
    components: {
    AboutUS,
    ButtonView
  },
  data() {
    return {
      AllData: AllData,
    };
  },
  }
</script>
<style>
</style>