<template >

  <router-view />
</template>
<script>
</script>
<style lang="less">

.heading-item {  
    font-size: 60px;
    color: white;
    display: flex;
    align-items: center;
    img {
      width: 239.93px;
    }
  }
  .section{
     
    justify-content: center;
    margin: 37px 37px;
    padding: 46px;
    border-radius: 50px;
    background-color: #050e47;
    border: 6px solid rgb(52, 157, 255);
  }
  @media only screen and (max-width: 600px) {
      .content{
        font-size: 18px;
      }
      h1{
        font-size: 24px;
      }
      .heading-item{
     display: flex;
     justify-content: center;
      }
      .person2 {
        margin-top: 20px;
      }
  }
</style>
