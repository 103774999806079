import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutusView from '../views/AboutusView.vue'
import FeaturesView from '../views/FeaturesView.vue'
import StrategicView from '../views/StrategicView.vue'
import OrganizationsView from '../views/OrganizationsView.vue'
import ContactUs from '../views/ContactUs.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/aboutus',
    name: 'aboutus',
    component: AboutusView
  },
  {
    path:'/features',
    name: 'features',
    component: FeaturesView
  },

  {
    path:'/strategic',
    name: 'strategic',
    component: StrategicView
  },
  {
    path:'/organizations',
    name: 'Organizations',
    component: OrganizationsView
  },
  {
    path:'/contact',
    name: 'contact',
    component: ContactUs
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
