<template>
     <div class="wave"></div>
     <div class="wave"></div>
     <div class="wave"></div>
     <div class="wave"></div>
  <section class="bg__image">
     <logoView/>
    <section class="grid__container">
      <div class="grid__items">
        <h1>“ Toktime <span>AI</span>字人直播購物，擁抱購物未來”</h1>
        <p>“與我們一起通過尖端技術和沉浸式體驗重新定義購物”</p>
      </div>
      <div class="person">
          <img  alt="avatar" src="../assets/image/DRIP_2.png" />
      </div>
    </section>
    <!-- nav -->
    <header class="navbar">
      <div class="nav_chil">
       <nav>
        <router-link to="/" exact active-class="">
          <div class="nav__item">
            <div class="item">
              <img src="../assets/image/home.svg" />
              <span>首頁</span>
            </div>
          </div>
        </router-link>
        <router-link to="/aboutus" exact active-class="">
          <div class="nav__item">
            <div class="item">
              <img src="../assets/image/user.svg" />
              <span>關於我們</span>
            </div>
          </div>
        </router-link>
        <router-link to="/features" exact active-class="">
          <div class="nav__item">
            <div class="item">
              <img src="../assets/image/stack.svg" />
              <span>特點優勢</span>
            </div>
          </div>
        </router-link>
        <router-link to="/strategic" exact active-class="">
          <div class="nav__item">
            <div class="item">
              <img src="../assets/image/data.svg" />
              <span>戰略規劃</span>
            </div>
          </div>
        </router-link>
        <router-link to="/organizations" exact active-class="">
          <div class="nav__item">
            <div class="item">
              <img src="../assets/image/check.svg" />
              <span>全球合作機構</span>
            </div>
          </div>
        </router-link>
        <router-link to="/Contact" exact active-class="">
          <div class="nav__item">
            <div class="item">
              <img src="../assets/image/call.svg" />
              <span>全球合作機構</span>
            </div>
          </div>
        </router-link>
       </nav>
       <div class="footer">
    <p>粤ICP备2023091186号</p>
  </div>
      </div>
      
    </header>
    <!-- <FooterView /> -->
  </section>
</template>
<script>
import logoView from '@/components/Logo.vue';

// import FooterView from "../components/Footer.vue";
export default {
  name: "HomePage",
  components: {
    logoView
},
};
</script>
 
<style lang="less" scoped>
.footer{
  color: white;
  font-weight: 800;
  font-size: 18px;
  margin-top: 40px;
  text-align: center;
}
.router-link:link{
  text-decoration: none;
}
.navbar{
    display: flex;
     justify-content: center;
}
.nav_chil{
    position: fixed;
    bottom: 1%;
}
h1 span{
    color: orange;
}
  .item{
    justify-content: center;
    padding: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }
  .item img{
    width: 54px;
  }
  .item span{
    font-size: 16px;
  }
.nav__item {
  display: flex;
  justify-content: center;
  border-radius: 12px;
  width: 150px;
  height: 150px;
  padding: 14px 14px;
  border: 2px solid rgb(204, 40, 171);
  box-shadow: 2px 6px 60px 10px rgb(177, 23, 146);
  color: white;
  text-decoration: none;


}
.nav__item:hover {
  background:  rgb(176, 23, 145);
  transition: 0.5s ease-in-out;

}
nav {
  display: flex;
  gap: 24px;
  justify-content: center;

}

section {
  padding: 16px 16px;
}

.grid__container {
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns:  auto auto;
}
.grid__items {
  color: white;
}
.grid__items h1 {
  text-align: center;
  font-size: 56px;
  font-weight: bold;
  text-transform: uppercase;
}
.grid__items p {
  margin-top: 80px;
  font-size: 32px;
  text-align: center;
  align-items: center;
}
.avatar {
  width: 500px;
}

@media only screen and (max-width: 600px) {
  .person img{
    width: 326px;
    margin-left: 20px;
  }
  .grid__container {
  margin-top: 56px;
  grid-template-columns:  none;
}
.nav__item{
  width: 52px;
  height: 52px;
}
.item img  {
  width: 23px;
}

.item span {
  font-size: 8px;
}
.grid__items h1 {
  font-size: 30px;
}
.grid__items p {
  font-size: 24px;
}
.footer p {
  font-size: 14px;
}


}

</style>
