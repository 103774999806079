<template> 
    <StrategicView :data="AllData.Strategic"/>
    <ButtonView/>
  </template>
  <script >
import ButtonView from "@/components/Button.vue";
 import StrategicView from '@/components/StrategicView.vue';
 import AllData from "@/services/drummydata.json";
    export default {
      name: 'StrategicPage',
      components: {
    StrategicView,
    ButtonView
  },
  data() {
    return {

      AllData: AllData,
    };
  },
    }
  </script>
  <style>
  </style>