<template>
  <section class="section">
    <div class="heading-item">
      <img src="../assets/image/g.svg" />
      <h1>{{ data.name }}</h1>
    </div>
    <ul class="content">
      <li v-for="(item,i) in data.tags" :key="i">
        {{item}}
      </li>
    </ul>

    <div class="line-item">
      <div>
        <p class="content">{{data.name_2}}</p>
        <img src="../assets/image/line.svg" />
        <div class="img-group">
          <img :src="item" v-for="(item,i) in data.img" :key="i" />
        </div>
      </div>
      <div class="person2">
        <img class="person" src="../assets/image/girl1.png" />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "AboutUS",

  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  
};
</script>
<style lang='less' scoped>
 .content{
  font-size: 34px;
  color: white;
 }
.section{
    margin: 37px 37px;
    padding: 46px;
    border-radius: 50px;
    background-color: #050e47;
    border: 6px solid rgb(52, 157, 255);
    .line-item {
      color: white;
      .person {
        width: 348px;
      }
      padding-top: 184px;
      display: flex;
      flex-direction: row;
      img {
        padding-top: 20px;
        width: 1282px;
      }
      .img-group {
        display: flex;

        img {
          width: 413px;
          padding: 22px;
        }
      }
    }
  }


  
  .line-item {
      .person {
        width: 348px;
      }
      padding-top: 184px;
      display: flex;
      flex-direction: row;
      span {
        font-size: 30px;
      }
      img {
        padding-top: 20px;
        width: 1282px;
      }
      .img-group {
        display: flex;

        img {
          width: 413px;
          padding: 22px;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .content{
        font-size: 18px;
      }
      h1{
        font-size: 24px;
      }
      .heading-item{
     display: flex;
     justify-content: center;
      }
      .person2 {
        margin-top: 20px;
      }
  }
</style>