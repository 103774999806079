<template> 
    <OrganizationsView :data="AllData.Organizations"/>
    <ButtonView/>
  </template>
  <script >
import ButtonView from "@/components/Button.vue";
import OrganizationsView from '@/components/OrganizationsView.vue';
import AllData from "@/services/drummydata.json";
    export default {
      name: 'OrganizationsPage',
      components: {
    OrganizationsView,
    ButtonView

  },
  data() {
    return {
      AllData: AllData,
    };
  },
    }
  </script>
  <style>
  </style>