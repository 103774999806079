<template>
    <section class="section-item2">
      <div class="heading-item">
        <img src="../assets/image/q.svg" />
        <h1>{{ data.name}}</h1>
      </div>
      <p class="content" style="color: #7596ff">
        {{ data.content }}
      </p>
      <div class="gird__container">
        <div class="gird-item">
          <img :src="item" v-for="(item,i) in data.sponsor" :key="i" alt=""/>
        </div>
      </div>
    </section>
</template>
<script>

export default {
   name:"OrganizationsView",
     data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
}
</script>

<style lang="less" scoped >
  .content {
    margin-top: 96px;
    font-size: 30px;￼
    p {
      font-size: 30px;
    }
  }
 .section-item2 {
    margin: 37px 37px;
    padding: 46px;
    background-color: #050e47;
    border: 8px solid rgb(52, 157, 255);
    .flex{
      justify-content: space-between;
    }
  }
  .heading-item {
    display: flex;
    align-items: center;
    img {
      width: 239.93px;
    }
    h1 {
      font-size: 60px;
      padding-left: 43px;
    }
  }

  .gird__container {
    margin-top: 107px;
    justify-content: space-between;
    display: grid;
    gap: 14px;
    // grid-template-columns: auto auto auto auto auto;
    grid-gap: 30px;
    justify-content: center;
  }

  .gird-item {
    img {
      width: 325px;
          margin-left:10px;
      // margin-right: 0.2rem;
    }
  }

  @media only screen and (max-width: 600px) {
  .grid__container {
    grid-template-columns: auto auto;
    font-size: 18px;
  }
  .heading-item {
    font-size: 24px;
    display: flex;
    justify-content: center;
  }
  .person2 {
    width: 250px;
    margin-left: 120px;
  }
  .content{
    font-size: 18px;
  }
  h1{
     font-size: 34px;
  }
}

</style>