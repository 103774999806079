<template>
  <section class="Buttom">
    <div class="Go_home">
      <router-link to="/">
        <img class="back" src="../assets/image/back.svg" />
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "ButtonView",
};
</script>

<style lang="less" scoped >
.Go_home {
  display: flex;
  justify-content: center;
}
.back {
   padding: 14px;
  animation: scale 4s infinite ease-in-out;
  width: 90px;
}

@keyframes scale {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
@media only screen and (max-width: 600px) {
  .back{
    width: 80px;
  }
}
</style>
