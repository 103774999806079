<template>
  <section class="section">
    <div class="heading-item">
      <img src="../assets/image/l.svg" />
      <h1>{{ data.name }}</h1>
    </div>
    <p class="content" style="color: #c983fe">
      {{ data.content }}
    </p>
    <div class="grid__container">
      <div>
        <li
          v-for="(item, i) in data.ct"
          :key="i"
          class="content"
          style="color: #4be4b0"
        >
          {{ item }}
        </li>
        <p class="content" style="color: white">
          {{ data.h1 }}
        </p>
          <div class="footer-text">
            <p >
          無論您是普通人、商家、供應鏈渠道商、內容創作者還是普通的用戶？
        </p>
        <p>
          讓我們一起徹底改變人們的購物方式，併以您獨特的視角激勵他人。
        </p>
          </div>
      </div>
      <div>
        <img class="s-img" src="../assets/image/l.png" />
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ContactUs",
  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  margin-top: 40px;
  font-size: 24px;
}
  .grid__container {
    display: grid;
    grid-template-columns: auto auto;
  }
.footer-text p{
  margin-top: 24px;
    font-size: 20px;
    color: white;
  }
@media only screen and (max-width: 600px) {
  .grid__container {
    justify-content: center;
    align-items: center;
    grid-template-columns: auto;
    font-size: 18px;
  }
  .heading-item {
    font-size: 24px;
    display: flex;
    justify-content: center;
  }
  .person2 {
    width: 250px;
    margin-left: 120px;
  }
  .content {
    font-size: 18px;
  }
  .s-img {
    width: 273px;
  }
  .footer-text p{
    font-size: 18px;
  }

}
</style>
