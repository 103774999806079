<template>
  <div class="logo">
    <img src="../assets/image/logo.png" alt="logo" />
  </div>
</template>

<script>
export default {
  name: "logoView",
};
</script>
<style lang="less" scoped>
.logo {
    margin-top: 20px;
    margin-left: 40px;
    width: 200px;
  animation: scale 4s infinite ease-in-out;
}
 


@keyframes scale {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

@media only screen and (max-width: 600px) {

.logo{
    margin-top: 14px;
    width: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
}
}

</style>
