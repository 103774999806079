<template>
  <section class="section">
    <div class="heading-item">
      <img src="../assets/image/t.svg" />
      <h1>{{ data.name }}</h1>
    </div>
    <!-- content -->
    <section class="content">
      <div class="grid__container">
        <div class="text">
          <h1>{{ data.heading }}</h1>
          <p>
            {{ data.p }}
          </p>
          <h1>
            {{ data.heading2 }}
          </h1>
          <h1>{{ data.heading3 }}</h1>
          <h1>{{ data.heading4 }}</h1>
        </div>
        <div>
          <img class="person2" src="../assets/image/person2.png" />
        </div>
      </div>
    </section>
  </section>
</template>
<script>
export default {
  name: "FeaturesView",

  data() {
    return {};
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="less" scoped >
.heading-item {


  color: white;
}
.section {
  margin: 37px 37px;
  padding: 46px;
  background-image: url(../assets/image/bg_e.svg);
  border-radius: 50px;
  background-color: #050e47;
  border: 8px solid rgb(52, 157, 255);
  .flex {
    justify-content: space-between;
  }
}
.heading-item {  
    font-size: 60px;
    color: white;
    display: flex;
    align-items: center;
    img {
      width: 239.93px;
    }
  }
.bge {
  position: absolute;
  width: 752px;
  height: 771.43px;
  right: 148px;
}

.grid__container {
  display: grid;
  grid-template-columns: auto auto;
}
.person2 {
  width: 618px;
}
h1 {
  color: white;
}
p {
  color: white;
}
.content {
  margin-top: 14px;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .grid__container {
    grid-template-columns: auto;
    font-size: 18px;
  }
  .heading-item {
    font-size: 24px;
    display: flex;
    justify-content: center;
  }
  .person2 {
    width: 250px;
    margin-left: 120px;
  }
}
</style>
