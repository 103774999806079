<template> 
    <ContactUs  :data ="AllData.contact_us" />
    <ButtonView/>
  </template>
  <script >
  import ButtonView from "@/components/Button.vue";
  import AllData from "@/services/drummydata.json";
  import ContactUs from '@/components/ContactUs.vue';
    export default {
      name: 'ContactUS',
      components: {
    ContactUs,
    ButtonView
  },
  data() {
    return {
      AllData: AllData,
    };
  },
    }
  </script>
  <style >

  </style>