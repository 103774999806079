<template>
  <section class="section">
    <div class="heading-item">
      <img src="../assets/image/z.svg" />
      <h1>{{ data.name }}</h1>
    </div>


    <p class="content2" style="color: #49fafb">
      {{ data.content }}
    </p>

   <!-- container -->
   <div class="contianer">
    <div class="grid___contianer">
      <div class="heading">
        <li>{{ data.h1 }}</li>
        <p class="text-content" v-for="(item, i) in data.p1" :key="i">
          {{ item }}
        </p>
      </div>
      <div>
        <img class="c1" src="../assets/image/c1.png" />
      </div>
    </div>

    <div class="grid___contianer">
      <div>
        <img class="c1" src="../assets/image/c2.png" />
      </div>
      <div class="heading">
        <li>{{ data.h2 }}</li>
        <p class="text-content" v-for="(item, i) in data.p2" :key="i">
          {{ item }}
        </p>
      </div>
    </div>

    <div class="grid___contianer">
      <div class="heading">
        <li>{{ data.h3 }}</li>
        <p class="text-content" v-for="(item, i) in data.p3" :key="i">
          {{ item }}
        </p>
      </div>
      <div>
        <img class="c1" src="../assets/image/c3.png" />
      </div>
    </div>
    

    <div class="grid___contianer">
      <div>
        <img class="c1" src="../assets/image/c4.png" />
      </div>
      <div class="heading">
        <li>{{ data.h4 }}</li>
        <p class="text-content" v-for="(item, i) in data.p4" :key="i">
          {{ item }}
        </p>
      </div>
    </div>



    <div class="grid___contianer">
      <div class="heading">
        <li>{{ data.h5 }}</li>
        <p class="text-content" v-for="(item, i) in data.p5" :key="i">
          {{ item }}
        </p>
      </div>
      <div>
        <img class="c1" src="../assets/image/c5.png" />
      </div>
    </div>

    

   


   </div>
     <!-- end container -->
  </section>
</template>

<script >
export default {
  name: "StrategicView",
  data() {
    return {
      tl: null,
    };
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="less"  scoped>




.c1 {
  // width: 456px;
  width: 630px;
  margin-top: 50px;
}

.grid___contianer {
  color: white;
  font-size: 30px;
  justify-content: center;
  gap: 200px;
  margin-top: 40px;
  display: grid;
  grid-template-columns: auto auto;
}
.content2 {
 font-size: 36px;
}



@media only screen and (max-width: 600px) {
      .content{
        font-size: 18px;
      }
      .grid__container{
        gap: 40px;
      }
  }
</style>
